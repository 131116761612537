import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import LatestCpt from '../bvv-styleguide/components/latest-cpt/LatestCpt'
import ImageIntroText from '../bvv-styleguide/components/image-intro-text/ImageIntroText'

export const ArchivePageTemplate = ({ content }) => {
  return (
    <main className="content" dangerouslySetInnerHTML={{ __html: content }} />
  )
}

ArchivePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const ArchivePage = ({ data }) => {
  const {
    allWordpressWpEvent: { edges },
  } = data
  const [loadedPosts, setLoadedPosts] = useState(8)
  let allEvents = []

  const getOlderCpt = () => setLoadedPosts(loadedPosts + 6)
  edges.map(event => allEvents.push(event.node))

  let firstEvent = allEvents.splice(0, 1)
  return (
    <Layout>
      <Seo title="Viðburðir" />
      <ImageIntroText
        title={firstEvent[0].title}
        text={firstEvent[0].content.slice(0.5)}
        postDate={firstEvent[0].date}
        buttonLink={`${firstEvent[0].slug}`}
        bgImageUrl={
          !firstEvent[0].featured_media
            ? firstEvent[0].featured_image_src
            : firstEvent[0].featured_media.source_url
        }
      />
      <LatestCpt
        title="Viðburðir"
        cptName="events"
        posts={allEvents}
        isOnArchive={true}
        onClick={getOlderCpt}
        loadedPosts={loadedPosts}
      />
    </Layout>
  )
}

ArchivePage.propTypes = {
  data: PropTypes.object.isRequired,
}
export default ArchivePage

export const archivePageQuery = graphql`
  query eventArchivePage {
    allWordpressWpEvent {
      edges {
        node {
          content
          link
          slug
          date
          title
          featured_media {
            source_url
          }
        }
      }
    }
  }
`
