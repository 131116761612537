import React from 'react'
import PropTypes from 'prop-types'

import { getMonthName } from '../../utils'
//Components
import Button from '../button/Button'
//Styles
import './latest-cpt.scss'
import BluePattern from '../image-intro-text/patterns/blue-pattern.png'

function LatestCPT({
  title,
  cptName,
  posts,
  isOnArchive = false,
  onClick = null,
  loadedPosts = 2,
}) {
  let cptLink = cptName.toLowerCase()
  return (
    <section className="latest-posts">
      <h1>{title}</h1>
      <div className="latest-posts__wrapper">
        {posts.map((post, index) => {
          const previewContent =
            post.content.rendered === undefined
              ? `${post.content.substring(1, 140)}...`
              : `${post.content.rendered.substring(1, 140)}...`
          const finalDate = getMonthName(post.date)
          return (
            <div
              className={
                index <= loadedPosts
                  ? `latest-posts__single-wrapp`
                  : `latest-posts__single-wrapp latest-posts__single-wrapp--hidden`
              }
              key={index}>
              <a
                href={
                  isOnArchive
                    ? post.slug
                    : `${title.toLowerCase()}/${post.slug}`
                }
                className="latest-posts__single"
                key={index}>
                <div
                  className="latest-posts__bg-image"
                  style={{
                    backgroundImage: `url(${
                      !post.featured_media
                        ? BluePattern
                        : isNaN(post.featured_media)
                        ? post.featured_media.source_url
                        : post.featured_image_src
                    })`,
                  }}
                />
                <p className="latest-posts__date date">{finalDate}</p>
                <h2>
                  {post.title.rendered !== undefined
                    ? post.title.rendered
                    : post.title}
                </h2>
                <div dangerouslySetInnerHTML={{ __html: previewContent }}></div>
              </a>
            </div>
          )
        })}
      </div>
      {!isOnArchive ? (
        <Button
          text={
            title === 'Viðburðir'
              ? `Allir ${title.toLowerCase()}`
              : `Allar ${title.toLowerCase()}`
          }
          backgroundColor="secondary"
          link={`/${title == 'Viðburðir' ? 'vidburdir' : 'frettir'}`}
        />
      ) : posts.length < 9 ? null : (
        <Button
          text={`Eldri ${title.toLowerCase()}`}
          backgroundColor="secondary"
          onClick={onClick}
        />
      )}
    </section>
  )
}

LatestCPT.propTypes = {
  /**
   * The title of section
   */
  title: PropTypes.string,
  /**
   * The name of CPT
   */
  cptName: PropTypes.string,
  /**
   * This is object of posts from graphql query
   */
  posts: PropTypes.array.isRequired,
  /**
   * On click button
   */
  onClick: PropTypes.func,
  /**
   * Number of Posts to be displayed
   */
  loadedPosts: PropTypes.number,
}
export default LatestCPT
