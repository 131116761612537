import React from 'react'
import PropTypes from 'prop-types'

import './image-intro-text.scss'

import { getMonthName } from '../../utils'
//Components
import Button from '../button/Button'
//Patterns
import BluePattern from './patterns/blue-pattern.png'
import BrownPattern from './patterns/brown-pattern.png'
import PinkPattern from './patterns/pink-pattern.png'

export default function ImageIntroText({
  title,
  text,
  postDate,
  bgImageUrl,
  pattern = 'blue',
  imagePosition = 'left',
  buttonText = 'Meira',
  buttonLink = null,
}) {
  const patterns = {
    blue: BluePattern,
    brown: BrownPattern,
    pink: PinkPattern,
  }

  const finalDate = getMonthName(postDate)
  return (
    <section className={`image-intro image-intro--${pattern}`}>
      <div
        className={`image-intro__pattern image-intro__pattern--${imagePosition}`}
        style={{ backgroundImage: `url(${patterns[pattern]})` }}></div>
      <div
        className={`image-intro__wrapper image-intro__wrapper--${imagePosition}`}>
        <div
          className="image-intro__img"
          style={{ backgroundImage: `url(${bgImageUrl})` }}></div>
        <div className="image-intro__info">
          {finalDate !== null ? (
            <p className="date date--single">{finalDate}</p>
          ) : null}

          <h1> {title} </h1>
          {postDate ? (
            <p
              dangerouslySetInnerHTML={{
                __html: `${text.slice(0, 150)}...`,
              }}></p>
          ) : (
            <p> {text} </p>
          )}
          <Button text={buttonText} link={buttonLink} />
        </div>
      </div>
    </section>
  )
}

ImageIntroText.propTypes = {
  /**
   * Component title
   */
  title: PropTypes.string.isRequired,
  /**
   * Component text
   */
  text: PropTypes.string.isRequired,
  /**
   *  Component date
   */
  postDate: PropTypes.string,
  /**
   * Background image url
   */
  bgImageUrl: PropTypes.string.isRequired,
  /**
   * Pattern color
   */
  pattern: PropTypes.oneOf(['blue', 'brown', 'pink']),
  /**
   * Image position
   */
  imagePosition: PropTypes.oneOf(['left', 'right']),
  /**
   * Button text
   */
  buttonText: PropTypes.string,
  /**
   * Button text
   */
  buttonLink: PropTypes.string,
}

// ImageIntroText.propTypes
